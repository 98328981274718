export const ErrorHandler = (err: any): string => {
    let message = "";
    if (err && err.response && err.response.data) {
        if (err.response.data.message) {
            message = ": " + err.response.data.message;
        } else {
            message = ": " + err.response.data;
        }
    }
    return message;
}