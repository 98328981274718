import styles from "./Textbox.module.css";

type params = {
    isRequired?: boolean,
    label?: string,
    onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value?: string | number,
    placeholder?: string,
    type?: string,
    style?: any,
    name?: string,
    id?: string,
    iconClassName?: string,
    iconStyle?: any,
    disabled?: boolean,
    containerStyle?: any,
    valid?: boolean
}
const TextBox = ({ isRequired, label, onChangeHandler, value, placeholder, type, style, name, id, iconClassName, iconStyle, disabled = false, containerStyle, valid = true
}: params) => {

    let textBoxClasses = [styles.TextBox];
    if (!valid) {
        textBoxClasses.push(styles.Error)
    }

    return (<>
     <label style={{ color: '#95a1ab',fontSize:"1.2rem" }}>
                {label}
                {isRequired ? <span className={styles.required}>*</span> : ""}
            </label>
        <div className={styles.TextBoxContainer} style={containerStyle}>
           
            <input
                disabled={disabled}
                type={type}
                name={name}
                id={id}
                className={textBoxClasses.join(" ")}
                style={style}
                placeholder={placeholder}
                value={value}
                onChange={onChangeHandler}
            />
            {iconClassName && <i className={iconClassName} style={iconStyle}></i>}
        </div>
    </>
    )
}

export default TextBox;
