import { useEffect, useState } from "react";
import ApexCharts from "../common/ApexCharts/ApexCharts";
import Grid from "../common/Grid/Grid";
import { vpcGrid } from "./gridConfig";
import { vpc } from "./types";
import style from './VPC.module.css';
import axios from "../../util/axios";
import { PLATFROM_API } from "../../util/environment";
import { cloneDeep } from "lodash";
import { ApexOptions } from 'apexcharts'
import Spinner from "../common/Spinner/Spinner";

let red = "#f03800", green = "#0eab10", orange = "#faa200";
const VPC = () => {
  const [gridData, setGridData] = useState([])
  const [totalOptions, setTotalOptions] = useState({})
  const [availableOptions, setAvailableOptions] = useState({})
  const [allocatedOptions, setAllocatedOption] = useState({})
  const [allocatedPercentage, setAllocatedPercentage] = useState(0)
  const [availablePercentage, setAvailablePercentage] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const totalColor = "#008ffb"

  useEffect(() => {

    axios.get(`${PLATFROM_API}/vpc-ranges`)
      .then(({ data }) => {
        let allocated = 0, available = 0

        //set grid data
        let rows = cloneDeep(data)?.map((d: any, index: number) => {
          d.serialNo = (index + 1)
          if (d.platform_id) {
            d.isAllocated = process.env.PUBLIC_URL + "icons/tick.svg"
            allocated = allocated + 1
          } else {
            d.isAllocated = process.env.PUBLIC_URL + "icons/non-tick.svg"
            available = available + 1
          }
          return d
        })
        setGridData(rows)

        let availableColor = "#008ffb", allocatedColor = "#008ffb", allocatedVPCPercentage = 0, availableVPCPercentage = 0;
        if (rows?.length) {

          allocatedVPCPercentage = ((allocated / rows?.length) * 100)
          availableVPCPercentage = ((available / rows?.length) * 100)

          //allocated circle color
          if (allocatedVPCPercentage > 50) {
            allocatedColor = green
          } else if (allocatedVPCPercentage > 25) {
            allocatedColor = orange
          } else {
            allocatedColor = red
          }

          //available circle color
          if (availableVPCPercentage > 50) {
            availableColor = green
          } else if (availableVPCPercentage > 25) {
            availableColor = orange
          } else {
            availableColor = red
          }
        }

        if (availableColor === red) {
          availableVPCPercentage = 100
        }

        let options: ApexOptions = {
          colors: [totalColor],
          chart: {
            id: "availableVPC",
            height: 50,
            type: 'radialBar',
            offsetY: -20,
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              gradientToColors: ["#6db4e9"],
              stops: [0, 100]
            }
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '50%',
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 0.5,
                  left: 0,
                  blur: 4,
                  opacity: 0.15
                }
              },
              dataLabels: {
                name: {
                  show: true,
                  color: totalColor,
                },
                value: {
                  offsetY: 0.35,
                  fontSize: '1.6rem',
                  fontWeight: 500,
                  color: totalColor,
                  formatter: function (val: any) {
                    return rows?.length;
                  }
                }
              },
            },
          },
          labels: ['Total'],
        }

        //total option
        let totalOption = cloneDeep(options);
        totalOption.colors = [totalColor]
        if (totalOption.fill?.gradient)
          totalOption.fill.gradient.gradientToColors = ["#6db4e9"];
        if (totalOption?.plotOptions?.radialBar?.dataLabels?.name)
          totalOption.plotOptions.radialBar.dataLabels.name.color = totalColor
        if (totalOption.plotOptions?.radialBar?.dataLabels?.value) {
          totalOption.plotOptions.radialBar.dataLabels.value.color = totalColor
          totalOption.plotOptions.radialBar.dataLabels.value.formatter = function (val: any) {
            return rows?.length;
          }
        }
        totalOption.labels = ['Total']

        //allocated option
        let allocatedOption = cloneDeep(options);
        allocatedOption.colors = [allocatedColor]
        if (allocatedOption.fill?.gradient)
          allocatedOption.fill.gradient.gradientToColors = [allocatedColor];
        if (allocatedOption?.plotOptions?.radialBar?.dataLabels?.name)
          allocatedOption.plotOptions.radialBar.dataLabels.name.color = allocatedColor
        if (allocatedOption.plotOptions?.radialBar?.dataLabels?.value) {
          allocatedOption.plotOptions.radialBar.dataLabels.value.color = allocatedColor
          allocatedOption.plotOptions.radialBar.dataLabels.value.formatter = function (val: any) {
            return allocated.toString();
          }
        }
        allocatedOption.labels = ['Allocated']

        //available option
        let availableOption = cloneDeep(options);
        availableOption.colors = [availableColor]
        if (availableOption.fill?.gradient)
          availableOption.fill.gradient.gradientToColors = [availableColor];
        if (availableOption?.plotOptions?.radialBar?.dataLabels?.name)
          availableOption.plotOptions.radialBar.dataLabels.name.color = availableColor
        if (availableOption.plotOptions?.radialBar?.dataLabels?.value) {
          availableOption.plotOptions.radialBar.dataLabels.value.color = availableColor
          availableOption.plotOptions.radialBar.dataLabels.value.formatter = function (val: any) {
            return available.toString();
          }
        }
        availableOption.labels = ['Available']

        setAvailableOptions(availableOption)
        setAllocatedOption(allocatedOption)
        setTotalOptions(totalOption)
        setAvailablePercentage(availableVPCPercentage)
        setAllocatedPercentage(allocatedVPCPercentage)
        setIsLoading(false)
      })
      .catch((e: any) => setIsLoading(false))
  }, [])

  return (
    <>
      {isLoading ? <Spinner /> :
        <div className={style.GraphContainer}>
          <ApexCharts
            series={[100]}
            options={totalOptions}
            width={200}
            height={200}
            type="radialBar"
          />
          <ApexCharts
            series={[allocatedPercentage]}
            options={allocatedOptions}
            width={200}
            height={200}
            type="radialBar"
          />
          <ApexCharts
            series={[availablePercentage]}
            options={availableOptions}
            width={200}
            height={200}
            type="radialBar"
          />
        </div>
      }
      <div className={style.GridContainer}>
        <Grid columns={vpcGrid} gridData={gridData} />
      </div>
    </>
  )
}

export default VPC;