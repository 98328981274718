import React from 'react'
import style from "./Menu.module.css";

function Menu() {
  return (
    <div>
       <div className={style.Navbar}>
        </div>
    </div>
  )
}

export default Menu
