import axios from "../../util/axios";
import { ORDER_API } from "../../util/environment";
import { Toaster } from "../common/Toaster/Toaster";
import { Product, ProductType } from "./types";

export const getProducts = (): Promise<Product[]> => {
    return new Promise((resolve, reject) => {
        axios.get(ORDER_API + "/products")
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export const updateProduct = (productDetails: any) => {
    return new Promise((resolve, reject) => {
        axios.put(ORDER_API + `/product/${productDetails.id}`, productDetails)
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export const addNewProduct = (productDetails: any) => {
    return new Promise((resolve, reject) => {
        axios.post(ORDER_API + `/product`, productDetails)
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export const deleteProduct = (productId: string) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${ORDER_API}/product/${productId}`)
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}


export const addNewProductType = (name: string) => {
    return new Promise((resolve, reject) => {
        axios.post(ORDER_API + `/productType`, { name })
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export const editProductType = (productType: ProductType) => {
    return new Promise((resolve, reject) => {
        axios.put(ORDER_API + `/productType/${productType.id}`, { name: productType.name })
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}


export const deleteProductType = (id: number) => {
    return new Promise((resolve, reject) => {
        axios.delete(ORDER_API + `/productType/${id}`)
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export const getProductTypes = (): Promise<ProductType[]> => {
    return new Promise((resolve, reject) => {
        axios.get(ORDER_API + `/productTypes`)
            .then(({ data }) => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}