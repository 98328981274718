import { useEffect, useState, useRef } from "react";
import styles from "./Dropdown.module.css";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { cloneDeep } from "lodash"

type params = {
    isRequired?: boolean,
    name?: string,
    style?: any,
    items?: Array<{ id: string | number, name: string }>,
    placeHolder?: string,
    value?: string | number,
    onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    label?: string,
    disabled?: boolean
}
const DropDown = ({
    isRequired,
    name,
    style,
    items,
    placeHolder = "Select",
    value,
    onChangeHandler,
    label,
    disabled
}: params) => {
    const [selectedOption, setSeletedOption] = useState<{ id: string | number, name: string }>();
    const [clickOption, setClickOption] = useState<boolean>(false);
    const componentRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e: any) {
            if (componentRef && componentRef.current) {
                const ref = componentRef.current
                if (!ref.contains(e.target)) {
                    setClickOption(false);
                }
            }
        }
    }, []);

    useEffect(() => {
        let item = items?.find((item) => item.id == value);
        if (item)
            setSeletedOption(item)
    }, [value, items])

    // useEffect(() => {

    //     let item = items?.find((item) => item.id == value);
    //     console.log("items", items)
    //     console.log("item", item, ",", value)
    //     if (item)
    //         setSeletedOption(item)
    // }, [items])

    const displayDropdown = () => {
        setClickOption(!clickOption);
    };

    const optionClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        let eventObject: any = cloneDeep(e)
        //Setting the target value for anchor tag
        eventObject.target.value = e.currentTarget.getAttribute("data-value");
        eventObject.target.name = e.currentTarget.getAttribute("data-name");
        const selectedItem = items?.find((item) => "" + item.id == eventObject.target.value);
        setClickOption(false);
        setSeletedOption(selectedItem);
        if (onChangeHandler) {
            onChangeHandler(eventObject);
        }
    };


    return (
        <div className={styles.Container} ref={componentRef}>
            <label className={styles.label}>
                {label}
                {isRequired && <span className={styles.required}> *</span>}
            </label>
            <div
                className={!disabled ? styles.DropDown : [styles.DisabledDropDown, styles.DropDown].join(" ")}
                style={style}
                onClick={!disabled ? displayDropdown : undefined}
            >
                {selectedOption?.name || placeHolder}
                {/* <span>{!disabled && selectedOption?.name || placeHolder}</span> */}
                <span className={styles.icon}>
                    <FontAwesomeIcon icon={faAngleDown as any} size={"lg"} color={"#ff8300"} />
                </span>
                <div className={styles.dropdowncontent} >
                    {clickOption &&
                        items?.map((i) => (
                            <a key={i.id} id={i?.id?.toString()} data-name={name} onClick={optionClicked} data-value={i.id}>
                                {i.name}
                            </a>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default DropDown;