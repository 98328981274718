import Spinner from "../Spinner/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from "./Button.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
type params = {
    name?: string,
    onClickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    btnname?: string,
    leftIcon?: string,
    rightIcon?: IconProp,
    style?: any,
    secondary?: boolean,
    noBorder?: boolean,
    imgSrc?: string,
    onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    disabled?: boolean,
    loader?: boolean,
    iconStyle?: any,
    children?: any,
    imgStyle?: any,
    leftSvg?: boolean
}
const Button = ({
    name,
    onClickHandler,
    btnname,
    leftIcon,
    rightIcon,
    style,
    secondary,
    noBorder,
    imgSrc,
    onMouseEnter,
    onMouseLeave,
    disabled = false,
    loader,
    iconStyle,
    children,
    imgStyle,
    leftSvg
}: params) => {
    let buttonClasses = [styles.Button];
    if (secondary) {
        buttonClasses.push(styles.Secondary);
    }
    if (noBorder) {
        buttonClasses.push(styles.NoBorder);
    }

    return (
        <>
            {loader && (
                <Spinner
                    styles={{
                        borderWidth: "4.5px",
                        left: "12px",
                        top: "27%",
                        width: "20px",
                        height: "20px",
                        borderTopColor: "#012b49",
                    }}
                />
            )}
            <button
                name={btnname}
                disabled={disabled}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={buttonClasses.join(" ")}
                style={style}
                onClick={onClickHandler}
            >
                {leftIcon && <i className={[leftIcon, styles.LeftIcon].join(" ")} style={iconStyle}></i>}
                {imgSrc && <img src={process.env.PUBLIC_URL + imgSrc} style={imgStyle} alt="" />}
                {leftSvg && children}
                {name}
                {rightIcon && <FontAwesomeIcon icon={rightIcon as any} size={"lg"} color={"#ff8300"} />}
                {!leftSvg && children}
            </button>
        </>
    );
};

export default Button;
