
import style from "./Header.module.css";
import { useRef, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useOutsideAlerter from "../common/OutsideClickDetector";
import { login, logout } from "../../auth/keycloak";
import Menu from "../Menu/Menu";

const Header = () => {

    const hideSignInModal = () => {
        setSignInPopup(false)
    }

    const [signInPopup, setSignInPopup] = useState(false);
    const location = useLocation();
    const auth = useSelector((state: any) => state.auth);
    const wrapperRef = useRef(null);
    let navigate = useNavigate();
    useOutsideAlerter(wrapperRef, hideSignInModal);

    const toggleHandler = () => {
        setSignInPopup(!signInPopup)
    }


    const signinHandler = (e: any) => {
        e.stopPropagation();
        if (auth?.isAuthorized) {
            logout();
        } else {
            login()
        }
    }

    const onLogoClick = () => {
        navigate("/")
    }

    return (
        <>
        <Menu />
            <nav className={style.NavBar}>
                <div className={style.Container}>
                    <div className={style.LogoContainer} onClick={onLogoClick}>
                        <img className={style.Logo} src={process.env.PUBLIC_URL + '/images/kore_logo.png'} alt="" />
                        <span className={style.DeveloperText}>Marketplace</span>
                    </div>
                    <div className={style.Menu}>
                        <ul key="menu">
                            {auth?.isAuthorized && auth?.userData?.isMarketplaceAdmin && <>
                                <li key="admin" className={location?.pathname === '/admin' ? style.Active : ''}><Link to="admin">Admin</Link></li>
                            </>
                            }
                        </ul>
                    </div>
                    <div className={style.UserDetails}>
                        <div className={style.UserIcon}><i className="fa fa-user-circle-o"></i></div>
                        <div className={style.UserInfo} ref={wrapperRef} onClick={toggleHandler} >
                            <FontAwesomeIcon icon={faUserCircle as any} size={"3x"} style={{ marginRight: "1rem" }} />
                            <div className={style.UserData}>
                                {auth?.userData?.name && <div className={style.UserName}>Welcome {auth?.userData?.name}</div>}
                                <div>KORE WIRELESS</div>
                                <div className={signInPopup ? style.DropdownMenu : style.Hide}>
                                    <div className={style.DropdownItem} onClick={signinHandler} >{auth?.isAuthorized ? "Sign out" : "Sign in"}</div>
                                    {/* {auth?.isAuthorized && <div className={style.DropdownItem} ><Link to="gallery">Gallery</Link></div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;