import * as actionTypes from '../actions/actionTypes';


export type params = {
    isAuthorized: boolean,
    isAuthChecking: boolean,
    isApiUnAuthorized: boolean,
    userData: { name?: string, isMarketplaceAdmin: boolean }
}
const initialState: params = {
    isAuthorized: false,
    isAuthChecking: false,
    isApiUnAuthorized: false,
    userData: { isMarketplaceAdmin: false }
};

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case actionTypes.SET_IS_AUTHORIZE: {
            return { ...state, isAuthorized: action.isAuthorized }
        }

        case actionTypes.SET_IS_AUTHCHECKING: {
            return { ...state, isAuthChecking: action.isAuthChecking }
        }

        case actionTypes.UNAUTHORIZE_API: {
            return { ...state, isApiUnAuthorized: action.isApiUnAuthorized }
        }

        case actionTypes.SET_USERDATA: {
            let userData = { ...initialState.userData };
            if (action?.userName) {
                userData.name = action.userName
            }
            if (action?.isMarketplaceAdmin) {
                userData.isMarketplaceAdmin = action.isMarketplaceAdmin
            }
            return { ...state, userData: { ...userData } }
        }

    }
    return state;
};

export default authReducer;