import React, { useState } from "react";
import Products from "../Products/Products";
import VPC from "../VPC/VPC";
import style from "./Admin.module.css";

const Admin = () => {
    const [activeTab, setActiveTab] = useState("product")

    const tabItemClickHandler = (e: React.MouseEvent<HTMLLIElement>) => {
        setActiveTab(e.currentTarget.id)
    }
    return (
        <>
            <div className={style.Container}>
                <ul className={[style.TabMenu, "col-md-2 col-sm-3 col-xs-4"].join(" ")}>
                    <li className={activeTab === "product" ? [style.TabItem, style.Active].join(" ") : style.TabItem} id="product" onClick={tabItemClickHandler}>
                        <a >Product</a>
                    </li>
                    <li className={activeTab === "vpc" ? [style.TabItem, style.Active].join(" ") : style.TabItem} id="vpc" onClick={tabItemClickHandler}>
                        <a >VPC </a>
                    </li>
                </ul>
                <div className={[style.TabPane, "col-md-10 col-sm-9 col-xs-8"].join(" ")}>
                    {activeTab === "product" ?
                        <Products /> : activeTab === "vpc" && <VPC /> 

                    }
                </div>
            </div>
        </>
    )
}

export default Admin;