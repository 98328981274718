import { gridConfig } from "../common/Grid/types";

export const vpcGrid: gridConfig[] = [
    {
        type: "text",
        name: "serialNo",
        displayName: "#",
        config: {
            width: "13%"
        }
    },
    {
        type: "text",
        name: "vpc_cidr_block",
        displayName: "VPCCidrBlock"
    },
    {
        type: "image",
        name: "isAllocated",
        displayName: "isAllocated"
    },

]
