import { useEffect, useState } from "react";
import Button from "./../../common/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faSave, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TextBox from "../../common/Textbox/Textbox";
import style from "./AddProductType.module.css"
import { addNewProductType, deleteProductType, editProductType } from "../APIs";
import { Toaster } from "./../../common/Toaster/Toaster"
import ConfirmModal from "../../common/ConfirmModal/ConfirmModal";
import { ProductType } from "../types";

type params = {
    productType: ProductType[],
    updateProductType: (data: any) => void,
    hideModal: () => void
}

type modifyProductType = {
    id: number | null,
    name: string | null,
    action?: string
}

const AddProductType = ({ productType, updateProductType, hideModal }: params) => {
    const [productTypes, setproductTypes] = useState<modifyProductType[]>()
    const [isModifyType, setIsModifyType] = useState<any>()
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false)

    useEffect(() => {
        setproductTypes(productType)
    }, [])

    const iconClicked = (e: any) => {
        if (e.currentTarget.getAttribute("data-action") == "edit") {
            setproductTypes(productTypes?.filter((type: any) => type.id != null))
            setIsModifyType({
                id: e.currentTarget.getAttribute("data-id"),
                name: e.currentTarget.getAttribute("data-name"),
                action: "edit"
            })
        } else if (e.currentTarget.getAttribute("data-action") == "delete") {
            setIsModifyType({
                id: e.currentTarget.getAttribute("data-id"),
                name: e.currentTarget.getAttribute("data-name"),
                action: "delete"
            })
            setIsDelete(true)
        } else {
            if (productTypes?.find((type: any) => type.id == null))
                return
            if (productTypes) {
                setproductTypes(
                    [...productTypes, {
                        id: null,
                        name: null,
                        action: "add"
                    }])
            }
            setIsModifyType({
                action: "add"
            })

        }
    }

    const formChangeHandler = (e: any) => {
        setIsModifyType({ ...isModifyType, name: e.target.value })
    }

    const actionConfirmed = (e: any) => {
        if (!isModifyType?.name) {
            return Toaster("error", "Please enter a product Type")
        }
        setIsCallingAPI(true)
        if (e.currentTarget.getAttribute("data-id")) {
            editProductType(isModifyType).then(data => {
                setIsCallingAPI(false)
                let newProductTypes = productTypes?.map((type: any) => type.id == isModifyType.id ? {
                    name: isModifyType.name, id: isModifyType.id
                } : type)
                updateProductType(newProductTypes)
                setproductTypes(newProductTypes)
                setIsModifyType(null)
                Toaster("success", "Edited successfully")
            }).catch(error => {
                setIsCallingAPI(false)
                Toaster("error", "Error in updating")
            })
        } else {
            addNewProductType(isModifyType.name).then((data: any) => {
                setIsCallingAPI(false)
                const newProductTypes = productTypes?.map((type: any) => type.id == null ? {
                    name: isModifyType.name, id: data.id
                } : type)
                setproductTypes(newProductTypes)
                updateProductType(newProductTypes)
                setIsModifyType(null)
                Toaster("success", "Product type added successfully")
            }).catch(error => {
                setIsCallingAPI(false)
                Toaster("error", "Error in adding Product Type")
            })
        }
    }

    const deleteConfirmed = () => {
        setIsCallingAPI(true)
        deleteProductType(isModifyType.id).then(data => {
            setIsCallingAPI(false)
            const newProductTypes = productTypes?.filter((type: any) => type.id != isModifyType.id && type.id != null)
            updateProductType(newProductTypes)
            setproductTypes(newProductTypes)
            closeDeleteModal()
            Toaster("success", "Deleted successfully")
        }).catch(error => {
            setIsCallingAPI(false)
            Toaster("error", "Error in deleting")
        })
    }

    const closeDeleteModal = () => {
        setIsDelete(false)
    }

    const cancelAction = () => {
        setIsModifyType(null)
        setproductTypes(productTypes?.filter((type: any) => type.id != null))
    }

    const addBtnStyle = { padding: "0.8rem 3rem" }
    return <div className={style.Container}>
        <div className={style.ButtonContainer}>
            <Button
                onClickHandler={iconClicked}
                name={"Add"}
                style={addBtnStyle}
            />
        </div>
        <div className={style.TableContainer}  >
            <table className={style.Table}>
                <thead>
                    <tr>
                        <th>SI No</th>
                        <th>Name</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {productTypes?.map((type: any, index: number) => <tr>
                        <td>{index + 1}</td>
                        <td>{(isModifyType && (isModifyType.action == "edit" && type.id == isModifyType.id)) || (isModifyType && (isModifyType.action == "add" && type.id == null)) ?
                            <div className="row">
                                <div className="col-md-8">
                                    <TextBox
                                        placeholder="Name"
                                        disabled={isCallingAPI}
                                        type="text"
                                        onChangeHandler={formChangeHandler}
                                        containerStyle={{ width: "100%" }}
                                        name="name"
                                        value={isModifyType?.name || ""}
                                        style={{ height: "3rem" }}
                                    />
                                </div>
                                <div className={["col-md-2", style.SaveIconContainer].join(" ")}>
                                    <FontAwesomeIcon
                                        icon={faSave as any}
                                        title="Save"
                                        onClick={actionConfirmed}
                                        size="2x" data-id={type.id}
                                        color={"#ff8300"}
                                        className={isCallingAPI && type.id == isModifyType.id ? style.DisabledIcon : style.Icon}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTimesCircle as any}
                                        title="Cancel"
                                        onClick={cancelAction}
                                        size="2x" data-id={type.id}
                                        color={"#ff8300"}
                                        className={isCallingAPI && type.id == isModifyType.id ? style.DisabledIcon : style.Icon}
                                    />
                                </div>
                            </div>
                            : type.name
                        }</td>
                        <td className={style.IconColumn}>
                            {type.id != null && <FontAwesomeIcon
                                title="Edit"
                                icon={faEdit as any}
                                onClick={iconClicked}
                                size="lg"
                                data-id={type.id}
                                data-name={type.name}
                                data-action={"edit"}
                                className={style.Icon}
                            />}</td>
                        <td className={style.IconColumn}>
                            {type.id != null && <FontAwesomeIcon
                                title="Delete"
                                icon={faTrashAlt as any}
                                onClick={iconClicked}
                                size="lg" data-id={type.id}
                                data-name={type.name}
                                data-action={"delete"}
                                className={style.Icon}
                            />}</td>
                    </tr>)}
                    {productTypes?.length == 0 && <p>No Data avaialable</p>}
                </tbody>
            </table>
        </div>
        {/* <div style={{ "textAlign": "end" }}>
            <Button
                secondary={true}
                style={{
                    background: "transparent",
                    color: "#707070",
                    border: "1px solid #ff8300",
                }}
                onClickHandler={hideModal}
                name={"Cancel"}
            />
        </div> */}
        <ConfirmModal
            heading="Confirmation"
            message={`Are you sure you want to delete ${isModifyType?.name}?`}
            modalIsOpen={isDelete}
            closeModal={closeDeleteModal}
            onConfirm={deleteConfirmed}
            isConfirming={isCallingAPI}
        ></ConfirmModal>
    </div>

}
export default AddProductType;

