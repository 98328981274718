import React, { useEffect, useState } from "react";
import Button from "../common/Button/Button";
import DropDown from "../common/Dropdown/Dropdown";
import Textbox from "../common/Textbox/Textbox";
import style from "./Order.module.css";
import _ from "lodash";
import { Toaster } from "../common/Toaster/Toaster";
import axios from "axios";
import { ORDER_API } from "../../util/environment";
import moment from "moment";
import ConfirmModal from "../common/ConfirmModal/ConfirmModal";
import { ErrorHandler } from "../../util/errorHandler";
import {
  ENCRYPT_KEY,
  POSITION_LOGIC_DOMAIN_NAME,
} from "../../util/environment";
import Spinner from "../common/Spinner/Spinner";
import {
    CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import TextBox from "../common/Textbox/Textbox";
import { countryPhoneData, phone } from "phone";
import ReactCountryFlag from "react-country-flag";
let interval: any;
var AES = require("crypto-js/aes");
var crypto = require("crypto-js");

const MainPage = () => {
  type formParams = {
    name: string;
    email: string;
    region: string;
    domainName: string;
    token: string;
    validateEmail: string;
    country: string;
    organization: string;
  };
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [customerExist, setCustomerExist] = useState<boolean>(false);
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [otp, setOTP] = useState<number | null>(null);
  const [validatedEmail, setValidatedEmail] = useState<string | null>(null);
  const [initialFormData, setInitialFormData] = useState<formParams>({
    name: "",
    email: "",
    region: "",
    domainName: "",
    token: "",
    validateEmail: "",
    organization: "",
    country: "",
  });
  const [minutes, setMinutes] = useState<number | string | null>(null);
  const [seconds, setSeconds] = useState<number | string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [regions, setRegions] = useState();
  const [countries, setCountries] = useState();
  const [retries, setRetries] = useState(0);
  const [formData, setFormData] = useState([]);
  const [phoneData, setPhoneData] = useState<any[]>();
  const [formValues, setFormValues] = useState<any>({ token: "" });
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sections, setSections] = useState<any>({});
  const LargeScreen = useMediaQuery("(max-width: 1415px)");

  useEffect(() => {
    fetchFormData();
  }, []);

  useEffect(() => {
    var token: string | null = getUrlParameter("x-amzn-marketplace-token");
    if (!token) {
      token = localStorage.getItem("marketplaceToken");
      getOrderDetails(decryptToken(token));
    } else {
      const encryptedToken = AES.encrypt(token, ENCRYPT_KEY).toString();
      localStorage.setItem("marketplaceToken", encryptedToken);
      getOrderDetails(token);
      let search = JSON.parse(JSON.stringify(window.location.search));
      let params = new URLSearchParams(search);
      if (params && params.get("x-amzn-marketplace-token")) {
        params.delete("x-amzn-marketplace-token");
        window.location.replace(
          window.location.origin +
            `/order${params.toString() == "" ? "" : "?" + params}`
        );
      }
    }
    if (!token) {
      return Toaster(
        "error",
        "Token missing. Set up your account in AWS Marketplace"
      );
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let arr: any[] = [];
    countryPhoneData?.map((country: any) => {
      let data = {
        alpha2: country?.alpha2,
        countryCode: country?.country_code,
      };
      arr?.push(data);
      return data;
    });
    setPhoneData(arr);
  }, []);

  useEffect(() => {
    const isCountriesFormTypeExist = formData.some(
      (field: any) => field.name === "country"
    );
    const isRegionsFormDataExist = formData.some(
      (field: any) => field.name === "region"
    );

    if (isCountriesFormTypeExist == true) {
      getCountries();
    }

    if (isRegionsFormDataExist) {
      getAWSRegions();
    }
  }, [formData]);

  useEffect(() => {
    const groups = formData
      ? formData.reduce(
          (groups: any, item: any) => ({
            ...groups,
            [item.column]: [...(groups[item.column] || []), item],
          }),
          {}
        )
      : {};
    setSections(groups);
  }, [formData]);

  const APIErrorHandler = (errorMessage: string, error: any) => {
    //to do: retry APIS
    if (retries <= 3) {
      setTimeout(() => {
        setRetries(retries + 1);
        fetchFormData();
        getAWSRegions();
        getCountries();
      }, 15000);
    }

    setIsModalOpen(false);
    setIsSaving(false);
    let message = ErrorHandler(error);
    Toaster("error", errorMessage + message);
  };
  const fetchFormData = async () => {
    try {
      const token: any = decryptToken(localStorage.getItem("marketplaceToken"));
      const response = await fetch(`${ORDER_API}/ui-config`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: encodeToBase64(token) }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch form data");
      }

      const data = await response.json();
      setFormData(data.data);
      setIsLoading(false);
    } catch (error) {
      APIErrorHandler("Error fetching form data:", error);
      setIsLoading(false);
    }
  };

  const getAWSRegions = () => {
    axios
      .get(`${ORDER_API}/aws-regions`)
      .then((data) => {
        setRegions(data.data);
      })
      .catch((error) => {
        APIErrorHandler("Error in getting regions", error);
      });
  };

  const getCountries = () => {
    axios
      .get(`${ORDER_API}/countries`)
      .then((data) => {
        setCountries(data.data);
      })
      .catch((error) => {
        APIErrorHandler("Error in getting countries", error);
      });
  };

  const startTimer = (duration: number) => {
    var timer: number = duration,
      minute: number | string,
      second: number | string;
    const intervalId = setInterval(() => {
      minute = Math.floor(timer / 60);
      second = timer % 60;

      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;

      setMinutes(minute);
      setSeconds(second);
      if (--timer < 0) {
        clearInterval(intervalId);
      }
    }, 1000);
  };

  const decryptToken = (token: string | null) => {
    if (!token) return null;
    const bytes = crypto.AES.decrypt(token, ENCRYPT_KEY);
    const decrypt = bytes.toString(crypto.enc.Utf8);
    return decrypt;
  };

  const isAlphaNumeric = (str: string) => {
    if (!str) return false;
    var code, i, len;
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (
        !(code > 47 && code < 58) &&
        !(code > 64 && code < 91) &&
        !(code > 96 && code < 123)
      ) {
        return false;
      }
    }
    return true;
  };

  const getUrlParameter = (name: string) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1]);
  };

  const validateEmail = (email: string) => {
    return /^\S+@\S+\.\S+$/.test(email);
  };
  const encodeToBase64 = (token: string) => {
    return window.btoa(token);
  };
  const saveOrderHandler = () => {
    const token: any = decryptToken(localStorage.getItem("marketplaceToken"));
    setIsSaving(true);
    let updatedFormValues = {};

    const companyAddressSectionExists = formData.some(
      (field: any) => field.section === "companyAddress"
    );
    const dialerCode = countryPhoneData.find(
      (p: any) => p.alpha2 === formValues?.countryIso2
    );
    const formattedPhoneNumber = phone(
      `+${dialerCode?.country_code}${formValues?.phone}`
    );

    if (companyAddressSectionExists) {
      const companyAddress = {
        address1: formValues.address1 || "",
        address2: formValues.address2 || "",
        city: formValues.city || "",
        state: formValues.state || "",
        zip: formValues.zip || "",
      };

      const { address1, address2, city, state, zip, phone, ...rest } =
        formValues;

      updatedFormValues = {
        ...rest,
        ...(formattedPhoneNumber?.isValid
          ? { phone: formattedPhoneNumber.phoneNumber }
          : {}),
        companyAddress: companyAddress,
        token: encodeToBase64(token),
      };
    } else {
      updatedFormValues = {
        ...formValues,
        ...(formattedPhoneNumber?.isValid
          ? { phone: formattedPhoneNumber.phoneNumber }
          : {}),
        token: encodeToBase64(token),
      };
    }

    axios
      .post(`${ORDER_API}/order`, updatedFormValues)
      .then((data) => {
        setIsModalOpen(false);
        setIsSaving(false);
        setCustomerExist(true);
        setInitialFormData(formValues);
        Toaster("success", "Customer details added successfully");
      })
      .catch((error) => {
        setIsModalOpen(false);
        setIsSaving(false);
        Toaster("error", "Error in saving customer details");
      });
  };

  const updateOrderHandler = () => {
    setIsSaving(true);
    const token: any = decryptToken(localStorage.getItem("marketplaceToken"));
    const request = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      token: encodeToBase64(token),
    };
    axios
      .patch(`${ORDER_API}/order`, request)
      .then((data) => {
        setShowOTP(false);
        setIsSaving(false);
        setCustomerExist(true);
        setInitialFormData(formValues);
        Toaster("success", "Customer details edited successfully");
      })
      .catch((error) => {
        setIsSaving(false);
        Toaster("error", "Error in saving customer details");
      });
  };

  const saveHandler = (e: any) => {
    const token = decryptToken(localStorage.getItem("marketplaceToken"));
    if (!token)
      return Toaster(
        "error",
        "Token missing. Set up your account in AWS Marketplace"
      );
    if (!validateEmail(formValues.email) || formValues.email?.length === 0)
      return Toaster("error", "Email is not valid");
    if (formValues.email != validatedEmail)
      return Toaster("error", "Email is not verified.");
    const dialerCode = countryPhoneData.find(
      (p: any) => p.alpha2 === formValues?.countryIso2
    );
    const formattedPhoneNumber = phone(
      `+${dialerCode?.country_code}${formValues?.phone}`
    );
    const isPhoneExist =
      formValues.hasOwnProperty("phone") && formValues.phone !== "";
    if (isPhoneExist && !formattedPhoneNumber?.isValid) {
      return Toaster("error", "Phone is not valid");
    }
    if (e.target.name == "Update") {
      updateOrderHandler();
    } else if (e.target.name == "Submit") {
      const requiredFields = formData.filter((field: any) => field.isRequired);
      const emptyFields = requiredFields.filter(
        (field: any) => !formValues[field.name]
      );
      if (emptyFields.length > 0) {
        const fieldLabels = emptyFields
          .map((field: any) => field.label)
          .join(", ");
        return Toaster(
          "error",
          `Please enter all required fields: ${fieldLabels}`
        );
      }
      // if (!isAlphaNumeric(formValues.domainName))
      //     return Toaster("error", "Domain name should be alphanumeric")
      // if (!isAlphaNumeric(formValues.organization))
      //     return Toaster("error", "Organization should be alphanumeric")
      setIsModalOpen(true);
    }
  };

  const getOrderDetails = async (token: string) => {
    const encodedToken = encodeToBase64(token);
    setIsSaving(true);
    axios
      .get(`${ORDER_API}/order?regToken=${encodedToken}`)
      .then((data: any) => {
        setIsSaving(false);
        if (data.data.email && data.data.firstName && data.data.lastName)
          setCustomerExist(true);
        setValidatedEmail(
          data.data.validateEmail ? data.data.validateEmail : null
        );
        setInitialFormData({ ...data.data, token: formValues.token });
        setFormValues({
          ...data.data,
          ...data.data.companyAddress,
          token: formValues.token,
        });
        if (data.data.simQuantity) {
          setSelectedOption(data.data.simQuantity);
        }
      })
      .catch((error) => {
        setIsSaving(false);
        setCustomerExist(false);
        setFormValues({ token: "" });
        // Toaster("error", "You haven't saved any details yet")
      });
  };

  const getOtp = (email: string) => {
    if (!email || !validateEmail(email)) {
      return Toaster("error", "Please provide a valid email");
    }
    const token: string | null = decryptToken(
      localStorage.getItem("marketplaceToken")
    );
    if (!token) {
      return Toaster(
        "error",
        "Token missing. Set up your account in AWS Marketplace"
      );
    }
    setIsValidatingEmail(true);

    const request = {
      email: email,
      token: encodeToBase64(token),
    };
    axios
      .post(`${ORDER_API}/email/validate`, request)
      .then((data) => {
        const expiry = moment(
          moment(data.data.otpExpiryTime).format("YYYY-MM-DD HH:mm:ss")
        );
        const utcTime = moment(moment().utc().format("YYYY-MM-DD HH:mm:ss"));
        const remaingTime: number = expiry
          ? expiry.diff(utcTime, "seconds")
          : 0;
        if (remaingTime > 0 && remaingTime <= 300) {
          clearInterval(interval);
          startTimer(remaingTime);
        } else {
          setMinutes(0);
          setSeconds(0);
        }
        setShowOTP(true);
        setIsValidatingEmail(false);
        Toaster(
          "success",
          "Verification Code has been successfully sent to your email"
        );
      })
      .catch((error) => {
        setIsValidatingEmail(false);
        let message = ErrorHandler(error);
        Toaster("error", "Error in sending Verification Code" + message);
      });
  };

  const confirmOTP = () => {
    setIsConfirming(true);
    const token: any = decryptToken(localStorage.getItem("marketplaceToken"));
    const request = {
      otp: otp,
      token: encodeToBase64(token),
    };

    axios
      .post(`${ORDER_API}/email/confirm`, request)
      .then((data) => {
        setShowOTP(false);
        setOTP(null);
        setIsConfirming(false);
        setValidatedEmail(formValues?.email);
        Toaster("success", "Email has been verified");
      })
      .catch((error) => {
        setIsConfirming(false);
        let message = ErrorHandler(error);
        Toaster("error", "Error in verifying Verification Code" + message);
      });
  };

  const otpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOTP(parseInt(e.target.value));
  };

  const closeConfirmModal = () => {
    setIsModalOpen(false);
  };

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (option: string) => {
    const newValue = selectedOption === option ? "" : option;
    setFormValues({ ...formValues, simQuantity: newValue });
    setSelectedOption(newValue);
  };

  const textbox = (field: any) => {
    return (
      <Textbox
        key={field?.name}
        label={field?.label}
        type={field?.type}
        onChangeHandler={onChangeHandler}
        name={field?.name}
        value={formValues[field?.name] || ""}
        isRequired={field?.isRequired}
        disabled={
          customerExist &&
          !["firstName", "lastName", "email"].includes(field.name)
        }
      />
    );
  };

  const dropdown = (field: any) => {
    let items: any[] =
      field.options && field.options.length > 0 ? field.options : [];
    if (!items.length) {
      switch (field.name) {
        case "region":
          items = regions || [];
          break;
        case "country":
          items = countries || [];
          break;
        default:
          break;
      }
    }
    return (
      <DropDown
        key={field.name}
        label={field.label}
        name={field.name}
        items={items}
        onChangeHandler={onChangeHandler}
        value={formValues[field.name] || ""}
        isRequired={field?.isRequired}
        disabled={
          customerExist &&
          !["firstName", "lastName", "email"].includes(field.name)
        }
      />
    );
  };

  const checkbox = (field: any) => {
    return (
      <div>
        <label className={style.SimQuantity}>{field.label}</label>
        {field.options.map((option: any) => (
          <div key={option.value}>
            <input
              type="checkbox"
              id={option.value}
              name={field.name}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleCheckboxChange(option.value)}
              required={field?.isRequired}
              disabled={
                customerExist &&
                !["firstName", "lastName", "email"].includes(field.name)
              }
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    );
  };

  const phoneNumber = (field: any) => (
    <>
      <div style={{ display: "flex" }}>
        <div className={"col-md-3"}>
          <InputLabel
            component="label"
            sx={{
              " .MuiFormLabel-asterisk": {
                color: "#FF0000",
                fontSize: "1.4rem",
              },
            }}
            style={{
              color: "#95a1ab",
              fontWeight: "400",
              paddingTop: "0rem",
              opacity: "1",
              letterSpacing: "0px",
              fontSize: "1.2rem",
              marginBottom: "0rem",
              fontFamily: "'Open Sans',sans-serif",
            }}
            id={field.name}
            required={field.isRequired}
          >
            {field.label}
          </InputLabel>
          <FormControl
            variant="standard"
            style={{
              width: "90%",
              height: "4rem",
              background: "#FFFFFF",
              paddingTop: "0.5rem",
              border: "1px solid #9E9E9E",
              borderRadius: "4px 0px 0px 4px",
              opacity: "1",
              marginTop: "1.35rem",
            }}
          >
            <Select
              disableUnderline
              labelId={field?.name}
              id={field?.name}
              value={formValues[field.name] || ""}
              defaultValue={formData[field.name]}
              name={field?.name}
              onChange={onChangeHandler as any}
              defaultChecked={true}
              displayEmpty
              disabled={
                customerExist &&
                !["firstName", "lastName", "email"].includes(field.name)
              }
              sx={{
                height: "3rem",
                backgroundColor: "#FFFFFF",
                color: "black",
                opacity: "1",
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: "400",
                fontSize: "1rem",
                "& #custom-select-code": { paddingRight: "0px" },
              }}
            >
              {phoneData &&
                phoneData.map((phone: any) => (
                  <MenuItem
                    style={{ fontSize: "1.3rem" }}
                    id={phone?.alpha2}
                    key={phone?.alpha2}
                    value={phone?.alpha2}
                  >
                    {
                      <ReactCountryFlag
                        countryCode={phone?.alpha2}
                        svg
                        style={{
                          width: "2rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                    }
                    {phone?.alpha2 + " +(" + phone?.countryCode + ")"}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className={["col-md-9", style.Mobile].join(" ")}>
          <TextBox
            style={{ borderRadius: "0px 4px 4px 0px", marginLeft: "-0.7rem" }}
            label={"Mobile number"}
            value={formValues.phone}
            onChangeHandler={onChangeHandler}
            name={"phone"}
            disabled={
              customerExist &&
              !["firstName", "lastName", "email"].includes(field.name)
            }
            isRequired
          />
        </div>
      </div>
    </>
  );

    const updatebtnStyle = {
        background: customerExist ? "#8BC34A" : "#FF8300 0% 0% no-repeat padding-box",
        borderRadius: "4px",
        color: "#FFFFFF",
        textTransform: "uppercase",
        border: "none",
        opacity: "1",
        padding: "1.1rem 7.6rem 1.3rem 7.1rem",
        marginTop: "2rem",
        fontSize: "1.4rem",
        width: "21rem",
        height: "4.3rem",
        marginBottom: "2rem",
        fontFamily: "'Montserrat',sans-serif",
        fontWeight: "600",
        textAlign: "center"
    }
    const renderFields = (formData: any[], column: string) => {
        return formData.map((field: any) => {
            if (field.column === column) {
                switch (field.formType) {
                    case "textbox":
                        return textbox(field);
                    case "dropdown":
                        return dropdown(field);
                    case "domain":
                        return (
                            <div className={style.EmailFieldContainer}>
                                <div className="col-md-9">
                                    {textbox(field)}
                                </div>
                                <div className="col-md-3">
                                    <label className={style.DomainLabel}>.{POSITION_LOGIC_DOMAIN_NAME}</label>
                                </div>
                            </div>
                        );
                    case "phone":
                        return phoneNumber(field);
                    case "email":
                        return (
                            <>
                                <div className={style.EmailFieldContainer}>
                                    <div className="col-md-9">
                                        {textbox(field)}
                                    </div>
                                    <div className={["col-md-3", style.BtnContainer].join(" ")}  >
                                        <Button
                                            style={{ marginLeft: LargeScreen ? "1rem" : "2.5rem", marginRight: "-1rem",marginTop:"2.2rem" }}
                                            name={"Send Code"}
                                            onClickHandler={() => getOtp(formValues[field.name])}
                                            disabled={validatedEmail === formValues[field.name] || isValidatingEmail}
                                        />
                                    </div>
                                </div>
                                {showOTP &&
                                    <>
                                        <div className={style.EmailFieldContainer}>
                                            <div className="col-md-8">
                                                <Textbox
                                                    label="Verification Code" type="number"
                                                    onChangeHandler={otpChange}
                                                    name="otp"
                                                    value={otp || ""}
                                                />
                                            </div>
                                            <div className={["col-md-2", style.TimerContainer].join(" ")}>{minutes == 0 && seconds == 0 ?
                                                <span className={style.Expired}>Expired 0:0</span> : <span>{minutes && minutes + ":" + seconds}</span>}
                                            </div>
                                            <div className={["col-md-2", style.BtnContainer].join(" ")}>
                                                <Button
                                                    disabled={isConfirming}
                                                    onClickHandler={confirmOTP}
                                                    name={"Confirm"}
                                                    style={{marginTop:"2rem",marginLeft:"-1rem"}}
                                                >
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        );
                    case "checkbox":
                        return checkbox(field);
                    default:
                        return null;
                }
            }
            return null;
        });
    }

  return (
    <>
      <div className={style.Container}>
                <div className={["col-md-9", style.Form].join(" ")}>
                    <div className={style.FormContainer}>
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress style={{ color: "#ff8300", height: "5rem", width: "5rem" }} />
                          </div>
                        ) : (
                            <>
                                {formData.length > 0 ? (
                                    <div className="row">
                                        <div className={style.Header}>ADDITIONAL INFORMATION</div>
                                        <div className="col-md-6">
                                            {renderFields(formData, "first")}
                                        </div>
                                        {/* Second Column */}
                                        <div className="col-md-6">
                                            {renderFields(formData, "second")}
                                        </div>
                                        {formData && (
                                            <div className={style.ButtonContainer}>
                                                <Button
                                                    name={customerExist ? "Update" : "Submit"}
                                                    btnname={customerExist ? "Update" : "Submit"}
                                                    onClickHandler={saveHandler}
                                                    style={updatebtnStyle}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={style.NoData}>No data found</div>
                                )}
                            </>
                        )}
                    </div>

                    <ConfirmModal
                        modalIsOpen={isModalOpen}
                        closeModal={closeConfirmModal}
                        message={""}
                        heading="Are you sure you want to submit following order Details?"
                        cancelText="Cancel"
                        confirmText="Confirm"
                        onConfirm={saveOrderHandler}
                        isConfirming={isSaving}
                        shouldCloseOnOverlayClick={true}
                    >
                        <>
                            {isSaving && (
                                <div className={style.Overlay}>
                                    <Spinner />
                                </div>
                            )}
                            <div>
                                <p>The following details except email cannot be modified once you place an order</p>
                                {formData.map((field: any) => (
                                    <div key={field.name}>
                                        {field.label}: <span>{formValues[field.name]}</span>
                                    </div>
                                ))}
                                {formValues.phone && (
                                    <p>
                                        Phone number: {(`+${countryPhoneData.find((p: any) => p.alpha2 === formValues?.countryIso2)?.country_code} ${formValues.phone}`)}
                                    </p>
                                )}
                            </div>
                        </>
                    </ConfirmModal>
                </div>
            </div>
        </>
    );

}
export default MainPage