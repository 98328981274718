import { ReactChild } from 'react';
import Modal from 'react-modal';
import Button from "./../Button/Button";
import style from './Modal.module.css';

type params = {
    modalIsOpen: boolean,
    afterOpenModal?: any,
    closeModal: any,
    heading?: string,
    message?: string,
    onConfirm?: any,
    isConfirming?: boolean,
    confirmText?: string,
    cancelText?: string,
    shouldCloseOnOverlayClick?: boolean,
    children: ReactChild,
    footerDisabled?: boolean
}

const ReactModal = ({ children, modalIsOpen, afterOpenModal, closeModal, heading, message, onConfirm, isConfirming, confirmText = "Confirm", cancelText = "Cancel", shouldCloseOnOverlayClick = true, footerDisabled }: params) => {

    const modalStyles = {
        overlay: {
            // position: fixed,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#00000066',
            zIndex: 1001
        },
        content: {
            top: '45%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            minWidth: '50vw',
            padding: '0',
            transform: 'translate(-50%, -50%)',
            overflowyY: 'auto',
            maxHeight: '90%',
            fontSize: "16px",
            color: "#002137"
        }
    };
    const btnStyle = {
        padding: ".75rem 2.5rem",
        margin: "10px 0 10px 10px"
    }
    return (
        <Modal
            isOpen={modalIsOpen || false}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={modalStyles}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            contentLabel="Example Modal"
            ariaHideApp={false}
            preventScroll={false}

        >
            <div className={style.ModalHead}>
                {heading}
                <span className={style.ModalClose} onClick={closeModal}>
                    <img src={process.env.PUBLIC_URL + "/icons/close.png"} />
                </span>
            </div>
            <div className={style.ModalContent}>
                <div className={style.FormContainer}>
                    {children}
                </div>
            </div>
            {!footerDisabled && <div className={style.ModalFooter}>
                {cancelText !== "" &&
                    <Button
                        onClickHandler={closeModal}
                        name={cancelText}
                        style={{
                            ...btnStyle, ...{
                                background: "transparent",
                                color: "#707070",
                                border: "1px solid #ff8300",
                            }
                        }}
                    />}
                <Button
                    onClickHandler={onConfirm}
                    name={confirmText}
                    disabled={isConfirming}
                    style={btnStyle}
                />
            </div>}
        </Modal>
    );
}

export default ReactModal;
