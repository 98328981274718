import { useEffect, useState } from "react";
import TextBox from "../../common/Textbox/Textbox";
import { cloneDeep, set } from "lodash";
import style from "./AddProduct.module.css"
import DropDown from "../../common/Dropdown/Dropdown";
import Button from "./../../common/Button/Button"
import { addNewProduct, updateProduct, getProductTypes } from "../APIs";
import AddProductType from "./AddProductType";
import { Toaster } from "./../../common/Toaster/Toaster"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactModal from "../../common/Modal/Modal";
import { ErrorHandler } from "../../../util/errorHandler";
import { Product, ProductType } from "../types";

type params = {
    formData: any,
    updateProducts: (product: Product) => void
    hideModal: () => void,
    products: Product[]
}
type marketplaceChargeType = string[]

const AddProduct = ({ formData, updateProducts, hideModal, products }: params) => {

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [productDetails, setProductDetails] = useState<any>()
    const [isConfirming, setIsConfirming] = useState<boolean>(false)
    const [marketplaceCharges, setMarketplaceCharges] = useState<marketplaceChargeType>([])
    const [isEditProductType, setIsEditProductType] = useState<boolean>(false)
    const [productTypes, setProductTypes] = useState<ProductType[]>([])
    const [isGettingProductType, setIsGettingProductType] = useState<boolean>(true)

    useEffect(() => {
        setProductDetails({ ...cloneDeep(formData), configurations: formData?.configurations ? JSON.parse(formData?.configurations) : null })
        if (formData.id) {
            setMarketplaceCharges(formData?.marketplaceCharges?.split(","))
            setIsEdit(true)
        } else {
            setMarketplaceCharges([""])
        }
        getProductTypes().then((data: ProductType[]) => {
            if (data && productDetails?.type) {
                const type = data.find((t: ProductType) => (t.name == formData.type))?.id
                let updatedProduct = cloneDeep(productDetails)
                updatedProduct.type = type
                setProductDetails(updatedProduct)
            } else if (data && formData?.type) {
                const type = data.find((t: ProductType) => (t.name == formData.type))?.id
                setProductDetails({
                    ...cloneDeep(formData),
                    configurations: formData?.configurations ? JSON.parse(formData?.configurations) : null,
                    type
                })
            }
            setProductTypes(data)
            setIsGettingProductType(false)
        }).catch(error => {
            setIsGettingProductType(false)
        })
    }, [])

    const formChangeHandler = (e: any) => {
        let data = cloneDeep(productDetails)
        set(data, e.target.name, e.target.value)
        setProductDetails(data)
    }

    const onConfirm = async () => {
        if (!productDetails.id || !productDetails.name || !productDetails.type || !productDetails.subscriptionType || marketplaceCharges.join(",") === "") {
            return Toaster("error", "All fields are required")
        }
        // setProductDetails({ ...productDetails, type: productTypes.find((detail: any) => detail.id == productDetails.type).name })
        setIsConfirming(true)
        if (isEdit) {
            const isDuplicateExist = products.filter((product: Product) => product.name == productDetails.name && product.id !== productDetails.id)
            if (isDuplicateExist.length > 0) {
                return Toaster("error", "A product with same name already exists")
            }
            updateProduct({
                ...productDetails,
                configurations: JSON.stringify({ description: productDetails.configurations?.description }),
                marketplaceCharges: marketplaceCharges?.join(","),
                type: productTypes.find((detail: ProductType) => detail.id == productDetails.type)?.name
            })
                .then(data => {
                    setIsConfirming(false)
                    updateProducts({
                        ...productDetails,
                        marketplaceCharges: marketplaceCharges?.join(","),
                        configurations: JSON.stringify({ description: productDetails.configurations?.description }),
                        type: productTypes.find((detail: ProductType) => detail.id == productDetails.type)?.name
                    })

                    hideModal()
                    return Toaster("success", "Product Details updated successfully")
                }).catch(error => {
                    setIsConfirming(false)
                    let message: string = ErrorHandler(error)
                    return Toaster("error", "Error in updating product" + message)
                })
        } else {
            const isDuplicateExist = products.filter((product: Product) => product.name == productDetails.name && product.id == productDetails.id)
            if (isDuplicateExist.length > 0) {
                setIsConfirming(false)
                return Toaster("error", "A product with same name already exists")
            }
            addNewProduct({
                ...productDetails,
                configurations: JSON.stringify({ description: productDetails.configurations?.description }),
                marketplaceCharges: marketplaceCharges?.join(","),
                type: productTypes.find((detail: ProductType) => detail.id == productDetails.type)?.name
            })
                .then(data => {
                    setIsConfirming(false)
                    updateProducts({
                        ...productDetails,
                        marketplaceCharges: marketplaceCharges?.join(","),
                        configurations: JSON.stringify({ description: productDetails.configurations?.description }),
                        type: productTypes.find((detail: ProductType) => detail.id == productDetails.type)?.name
                    })
                    hideModal()
                    return Toaster("success", "Product Details added successfully")
                }).catch(error => {
                    setIsConfirming(false)
                    let message: string = ErrorHandler(error)
                    return Toaster("error", "Error in adding new product" + message)
                })
        }
    }

    const ChargesChangeHandler = (e: any) => {
        let currentCharges: marketplaceChargeType = marketplaceCharges?.filter((charge: string) => charge);
        currentCharges[e.target.id] = e.target.value;
        setMarketplaceCharges(currentCharges)
    }

    const onChargesAdd = (e: any) => {
        e.stopPropagation();
        let currentCharges: any = cloneDeep(marketplaceCharges)
        if (currentCharges && currentCharges.length >= 10) {
            Toaster("error", "Charges limit exceeded");
            return;
        }
        if (!marketplaceCharges) {
            currentCharges = [""]
        } else if (currentCharges.filter((charge: string) => charge == "")?.length === 0) {
            currentCharges.push("")
        } else {
        }
        setMarketplaceCharges(currentCharges)
    }
    const onDeleteCharge = (e: any) => {
        e.stopPropagation();
        const chargeIndex = e.currentTarget.getAttribute('name');
        let updatedCharges: marketplaceChargeType = marketplaceCharges?.filter((charge: string, index: number) => index != chargeIndex)
        if (updatedCharges.length === 0) {
            updatedCharges = [""]
        }
        setMarketplaceCharges(updatedCharges)
    }

    const showDetailsPopUp = () => {
        setIsEditProductType(true)
    }

    const closeProductTypeModal = () => {
        setIsEditProductType(false)
    }

    const updateProductType = (newProductTypes: ProductType[]) => {
        setProductTypes(newProductTypes)
    }

    const manageBtn = {
        margin: 0,
        marginTop: "1.4rem",
        padding: "0.9rem 1.5rem",
        borderRadius: "0 5px 5px 0",
        width: "100%"
    }

    return <>
        <div className={style.FormContainer}>
            <div className="row">
                <div className="col-md-6">
                    <TextBox
                        placeholder="ID"
                        label="ID"
                        type="text"
                        containerStyle={{ width: "100%" }}
                        name="id"
                        value={productDetails?.id || ""}
                        onChangeHandler={formChangeHandler}
                        disabled={isEdit} />
                </div>
                <div className="col-md-6">
                    <TextBox
                        placeholder="Name"
                        label="Name"
                        type="text"
                        containerStyle={{ width: "100%" }}
                        name="name"
                        value={productDetails?.name || ""}
                        onChangeHandler={formChangeHandler}

                    />
                </div>
            </div>
            <div className="row">
                <div className={["col-md-4", style.PR0].join(" ")}>
                    <DropDown
                        disabled={isGettingProductType}
                        label="Type"
                        name="type"
                        items={productTypes}
                        onChangeHandler={formChangeHandler}
                        value={productDetails?.type || ""}
                    />
                </div>
                <div className={["col-md-2", style.BtnContainer].join(" ")} >
                    <Button
                        name="Manage"
                        style={manageBtn}
                        disabled={isGettingProductType}
                        onClickHandler={showDetailsPopUp}
                        secondary={true}
                    // value={productDetails?.type || ""}
                    />
                </div>
                <div className="col-md-6"  >
                    <DropDown
                        label="Subscription Type"
                        name="subscriptionType"
                        placeHolder="Select Subscription Type"
                        items={SUBSCRIPTION_TYPES}
                        onChangeHandler={formChangeHandler}
                        value={productDetails?.subscriptionType || ""}
                    />
                </div>
            </div>
            <label className={style.DescriptionLabel} >Description</label>
            <textarea
                rows={3}
                className={style.TextAreaStyles}
                maxLength={1000}
                onChange={formChangeHandler}
                name="configurations.description"
                defaultValue={productDetails?.configurations?.description}
            >
            </textarea>
            <p className={style.DescriptionText}>{1000 - (productDetails?.configurations?.description?.length || 0)}/ 1000</p>
            <div className={style.ChargeContainer}>
                {marketplaceCharges && marketplaceCharges.length > 0 &&
                    marketplaceCharges.map((charge: string, index: number) => {
                        return <div key={index} className={style.ChargeContent} style={{ "marginTop": 0 }}>
                            <div className="col-md-10">
                                <TextBox
                                    onChangeHandler={ChargesChangeHandler}
                                    value={charge}
                                    label={"Marketplace Charge " + (index + 1)}
                                    type="text"
                                    name="charge"
                                    placeholder={"Marketplace Charge " + (index + 1)}
                                    id={String(index)}
                                />
                            </div>
                            <div className="col-md-2" >
                                <div style={{ float: "right" }}>
                                    <Button
                                        onClickHandler={onDeleteCharge}
                                        btnname={String(index)}
                                        style={{
                                            margin: "14px 5px", padding: "0.5rem 0.75rem", background: "transparent",
                                            color: "#707070",
                                            border: "1px solid #ff8300",
                                        }}
                                        rightIcon={faMinus as any}
                                        iconStyle={{ margin: 0, color: "#ff8300" }}
                                    />
                                    {index === (marketplaceCharges.length - 1) && <Button
                                        onClickHandler={onChargesAdd}
                                        btnname={String(index)}
                                        rightIcon={faPlus as any}
                                        iconStyle={{ margin: 0, color: "#ff8300" }}
                                        style={{
                                            margin: "14px 5px", padding: "0.5rem 0.75rem", background: "transparent",
                                            color: "#707070",
                                            border: "1px solid #ff8300",
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    })}
            </div>
            <div className={style.Footer}>
                <Button
                    secondary={true}
                    style={{
                        background: "transparent",
                        color: "#707070",
                        border: "1px solid #ff8300",
                    }}
                    onClickHandler={hideModal}
                    name={"Cancel"}
                />
                <Button
                    onClickHandler={onConfirm}
                    name={"Confirm"}
                    disabled={isConfirming}
                    style={{ "textAlign": "end" }}
                />
            </div>
        </div>
        <ReactModal heading="Manage KORE Products" modalIsOpen={isEditProductType} footerDisabled={true} closeModal={closeProductTypeModal}>
            <AddProductType productType={productTypes} hideModal={closeProductTypeModal} updateProductType={updateProductType}></AddProductType>
        </ReactModal>
    </>

}
export default AddProduct;

const SUBSCRIPTION_TYPES = [
    {
        "id": "contract",
        "name": "SaaS contract"
    },
    {
        "id": "subscription",
        "name": "SaaS subscription"
    }
]