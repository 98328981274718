import ReactApexChart from "react-apexcharts";

const ApexCharts = ({ options, series, type, width, height }) => {
    return <ReactApexChart
        type={type}
        options={options}
        series={series}
        width={width}
        height={height}
    />;
};

export default ApexCharts;