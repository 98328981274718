import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
    let navigate = useNavigate();

    useEffect(() => {
        var token: string | null = getUrlParameter("x-amzn-marketplace-token")
        if (token) {
            navigate(`/order?x-amzn-marketplace-token=${token}`);
        }
    }, [])

    const getUrlParameter = (name: string) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1]);
    };

    return <>
        <img width={"100%"} src={process.env.PUBLIC_URL + "images/marketplace4.jpg"} />
    </>
}

export default LandingPage;