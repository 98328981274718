import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Button from "../common/Button/Button";
import { getProducts, deleteProduct } from "./APIs";
import style from "./Products.module.css";
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ReactModal from "../common/Modal/Modal";
import AddProduct from "./AddProduct/AddProduct";
import Spinner from "../common/Spinner/Spinner";
import ConfirmModal from "../common/ConfirmModal/ConfirmModal";
import { Toaster } from "./../common/Toaster/Toaster"
import { Product } from "./types";
import { ErrorHandler } from "../../util/errorHandler";
import { productTypesGrid } from "./gridConfig";
import Grid from "../common/Grid/Grid";

const Products = () => {
    const [products, setProducts] = useState<Product[]>([])
    const [editProduct, setEditProduct] = useState<Product | null | undefined | {}>(null);
    const [deleteProductId, setDeleteProductId] = useState<string | null>(null);
    const [isloading, setIsloading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        setIsloading(true)
        getProducts().then((data: Product[]) => {
            setIsloading(false)
            setProducts(data)
        }).catch(() => setIsloading(false))
    }, [])

    const onEditClick = (id: string) => {
        let product = products.find(p => p.id === id)
        setEditProduct(product);
    }

    const onAddClick = () => {
        setEditProduct({});
    }

    const onDeletelick = (id: string) => {
        setDeleteProductId(id);
    }

    const closeDeleteModal = () => {
        setDeleteProductId(null);
    }

    const deleteProductHandler = () => {
        if (!deleteProductId) {
            return;
        }
        setIsDeleting(true);
        deleteProduct(deleteProductId).then((d) => {
            setIsDeleting(false);
            setProducts(products.filter((product: Product) => (product.id != deleteProductId)))
            closeDeleteModal();
            Toaster("success", "Product deleted successfully")
        }).catch(e => {
            console.error("Error in deleting ", e)
            let message: string = ErrorHandler(e)
            Toaster("error", "Error in deleting product " + message)
            setIsDeleting(false);
        })
    }

    const hideModal = () => {
        setEditProduct(null);
    }

    const updateProducts = (product: any) => {
        const index = products.findIndex(p => p.id === product.id)
        if (index > -1) {
            products[index] = product
        } else {
            products.push(product)
        }
    }

    return <>
        <div className={style.Container}>
            {isloading ? <Spinner /> : <>
                <div className={style.AddBtnContainer}><Button secondary={true} onClickHandler={onAddClick} name="Add Product"></Button></div>
                {products?.length > 0 ? products.map(p => {
                    return <div className={style.ListItem} key={p.id}>
                        <div className={style.ListItemHeader}>{p.name?.toUpperCase()}
                            <div className={style.IconContainer}>
                                <span><FontAwesomeIcon onClick={() => onEditClick(p.id)} id={p.id} title="editProduct" icon={faEdit as any} size={"sm"} color={"#ea2839"} /></span>
                                <span><FontAwesomeIcon onClick={() => onDeletelick(p.id)} title="deleteProduct" icon={faTrashAlt as any} size={"sm"} color={"#ea2839"} /></span>
                            </div>
                        </div>
                        <div className={style.ListItemCode}>{p.id}</div>
                        <div className={style.ListItemDescription}>
                            {p.configurations ? JSON.parse(p.configurations)?.description : ""}
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <div className={style.ListItemSubHead}>Type: <span>{p.type}</span></div>
                            </div>
                            <div className="col-md-3">
                                <div className={style.ListItemSubHead}>Subscription Type:  <span>SaaS {p.subscriptionType}</span></div>
                            </div>
                            <div className={[style.ListItemSubHead, "col-md-6", style.Dflex].join(" ")}> Marketplace charges:
                                {/* <div className={style.ListItemCharges}> */}
                                {p.marketplaceCharges?.split(",").filter(Boolean).map((c: string, i: number) => {
                                    return <div> {i !== 0 && "|"} {c}</div>
                                })}
                            </div>
                        </div>
                    </div>
                }) : <div className={style.Noproducts}>No products available</div>}
            </>}
        </div>
        <ReactModal heading="Product Details" modalIsOpen={editProduct ? true : false} closeModal={hideModal} footerDisabled={true} >
            <AddProduct formData={editProduct} updateProducts={updateProducts} hideModal={hideModal} products={products} />
        </ReactModal>
        <ConfirmModal
            modalIsOpen={!!deleteProductId}
            closeModal={closeDeleteModal}
            shouldCloseOnOverlayClick={true}
            onConfirm={deleteProductHandler}
            isConfirming={isDeleting}
            heading={`Delete Confirmation`}
            message={`Are you sure you want to delete product ${deleteProductId} ?`}
        ></ConfirmModal>
    </>
}

export default Products;