import axios from 'axios';
import { ENCRYPT_KEY } from '../util/environment';

var crypto = require("crypto-js");

const decryptToken = (token: string | null) => {
    if (!token)
        return null
    const bytes = crypto.AES.decrypt(token, ENCRYPT_KEY);
    const decrypt = bytes.toString(crypto.enc.Utf8);
    return decrypt
}

const instance = axios.create();

instance.defaults.headers.common['Authorization'] = decryptToken(localStorage.getItem("accessToken"));

// instance.interceptors.request...

export default instance;