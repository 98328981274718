import { useEffect } from 'react';
import style from './App.module.css';
import Order from './components/Order/Order';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import LandingPage from './components/LandingPage/LandingPage';
import { params } from './store/reducers/authReducer';
import Admin from './components/Admin/Admin';

const PrivateRoute = ({ children }: any) => {
  const auth = useSelector((state: any) => state.auth);
  return auth?.isAuthorized ? children : <Navigate to="/" />;
}

function App() {
  const auth: params = useSelector((state: any) => state.auth);

  useEffect(() => {
  }, [])

  return (
    <div className={style.Main}>
      <Header />
      <ToastContainer toastStyle={{ color: 'white' }} />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/order' element={<Order />} />
        {auth?.userData?.isMarketplaceAdmin && < Route path="/admin" element={<PrivateRoute>
          <Admin />
        </PrivateRoute>} />}
        {/* <Route
          path="*"
          element={<Navigate to="/" />}
        /> */}
      </Routes>
    </div>
  );
}

export default App;
