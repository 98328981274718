import { useState } from "react";
import Spinner from "../Spinner/Spinner";
import style from "./Grid.module.css";
import { gridConfig } from "./types";
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

type params = {
    columns: gridConfig[],
    gridData: any[],
    onCheckItem?: (id: string) => void,
    gridRef?: any,
    onSort?: (column: string | null) => void,
    onCheckAllItems?: (isChecked: boolean) => void,
    isLoading?: boolean,
    smallTable?: boolean,
    onRowClicked?: (rowId: string) => void,
    highlightRow?: string | null,
    onEdit?: (rowId: string) => void
    onDelete?: (rowId: string) => void
}

const Grid = ({ columns, gridData, onCheckItem, onEdit, onDelete, gridRef, highlightRow, onSort, onCheckAllItems, onRowClicked, isLoading, smallTable = false }: params) => {

    const [isCheckedAll, checkAll] = useState(false);

    const onCheckAll = () => {
        checkAll(!isCheckedAll)
        if (onCheckAllItems) {
            onCheckAllItems(!isCheckedAll)
        }
    }

    const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onCheckItem) {
            onCheckItem(e.target.id)
        }
    }

    let tableStyle = [style.Table];
    if (smallTable) {
        tableStyle.push(style.SmallTable)
    }

    const sortHandler = (e: any) => {
        const column = e.currentTarget.getAttribute("id");
        if (onSort) {
            onSort(column)
        }
    }

    const rowClickHandler = (e: React.MouseEvent<HTMLTableRowElement>) => {
        if (onRowClicked) {
            onRowClicked(e.currentTarget.id)
        }
    }

    const editHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (onEdit) {
            onEdit(e.currentTarget.id)
        }
    }

    const deleteHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (onDelete) {
            onDelete(e.currentTarget.id)
        }
    }

    return (
        <div className={style.Container}>
            <table className={tableStyle.join(" ")} ref={gridRef}>
                <thead>
                    <tr>
                        {columns?.map((c, i) => {
                            return <th key={i} style={{ textAlign: c.config?.textAlign, width: c.config?.width }}>
                                {c.type === "checkbox" ? <input id={i.toString()} value={isCheckedAll ? isCheckedAll.toString() : undefined} type="checkbox" onChange={onCheckAll} /> :
                                    <>{c.displayName} <span id={c.name?.toString()} onClick={sortHandler} className={style.SortBtn}><i className="fa fa-angle-up"></i><i className="fa fa-angle-down"></i>
                                    </span></>
                                }
                            </th>

                        })}

                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading ? <td colSpan={columns?.length}><div className={style.loaderColumn}><Spinner styles={null} /></div></td> :
                            gridData?.map((data, rowIndex) => {
                                return <tr key={rowIndex} id={rowIndex.toString()} onClick={rowClickHandler} className={(highlightRow === rowIndex.toString()) ? style.HighlightRow : ""}>
                                    {columns?.map((c, i) => {
                                        return <td key={i} title={data[c.name]} style={{ textAlign: c.config?.textAlign }}>
                                            {c.type === "checkbox" ?
                                                <input id={rowIndex.toString()} type="checkbox" checked={data[c.name] || false} onChange={onCheck} />
                                                : c.type === "image" ? <img src={data[c.name]} width={c.config?.width} />
                                                    : c.type === "text" ? (data[c.name]?.toString().indexOf(",") != data[c.name]?.toString().lastIndexOf(",") && data[c.name]?.length > 0) ? data[c.name]?.substr(0, data[c.name]?.indexOf(",") + 1) + "..." : data[c.name]
                                                        : c.type === "date" && data[c.name] ? new Date(data[c.name])?.getDate() + " " + new Date(data[c.name])?.toLocaleString('default', { month: 'short' }) + " " + new Date(data[c.name])?.getFullYear() :
                                                            c.type === "icon" ? <span className={style.Icon} id={rowIndex.toString()} onClick={c.name === "edit" ? editHandler : c.name === "delete" ? deleteHandler : () => { }}><FontAwesomeIcon title="toggle" icon={c.name === "edit" ? faEdit as any : faTrashAlt as any} size={"1x"} color={"#636060"} /></span> : ""
                                            }
                                        </td>
                                    })}
                                </tr>

                            })
                    }
                    {(!gridData || gridData.length === 0 && !isLoading) && <tr><td className={style.Nodata} colSpan={columns?.length}>No data available </td></tr>}
                </tbody>
            </table>
        </div>
    )
}

export default Grid;